import React, {useState, useEffect} from 'react';
import LeaderBoardCard from '../components/LeaderBoardCard';
import { IoClose } from "react-icons/io5";
import { toast } from 'react-toastify';
import { UserService } from '../services/UserService';
import useLoading from '../hooks/useLoading';
const dummyUsers = [
   {
      id: 1,
      first_name: 'haqq',
      pointCount: 1000000,
    },
    {
      id: 2,
      first_name: 'james',
      pointCount: 500000,
    },
    {
      id: 3,
      first_name: 'john',
      pointCount: 240000,
    },
    {
      id: 4,
      first_name: 'haqq',
      pointCount: 1000000,
    },
    {
      id: 5,
      first_name: 'james',
      pointCount: 500000,
    },
    {
      id: 6,
      first_name: 'john',
      pointCount: 240000,
    },
    {
      id: 7,
      first_name: 'haqq',
      pointCount: 1000000,
    },
    {
      id: 8,
      first_name: 'james',
      pointCount: 500000,
    },
    {
      id: 9,
      first_name: 'john',
      pointCount: 240000,
    },
    {
      id: 10,
      first_name: 'haqq',
      pointCount: 1000000,
    },
    {
      id: 11,
      first_name: 'james',
      pointCount: 500000,
    },
    {
      id: 12,
      first_name: 'john',
      pointCount: 240000,
    },
    
];

const Leaderboard = () => {
  const [users, setUsers] = useState({})
  const [totalUsers, setTotalUsers] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const { startLoading, completeLoading, LoadingComponent } = useLoading();

  const getUsers = async () => {
    startLoading();
    UserService.getLeaderBorads()
      .then((res) => {
        const resData = res?.data;
        if (resData.success) {
            completeLoading();
            setUsers(resData?.data?.users);
            setTotalUsers(Number(resData?.data?.totalUsers + 2000000));
        }
      })
      .catch((err) => {
        completeLoading();
        // console.log(err);
        const resData = err?.response?.data;
        if (resData?.message) {
          // toast.error(resData?.message || "Something went wrong");
        }
      });
  };


  useEffect(() => {
    getUsers();
  }
  , []);

  return (
    <div className="flex flex-col min-h-screen bg-[#16171A] text-white p-6">
      {LoadingComponent}
      <div className="absolute top-6 left-6">
      <h1 className="text-3xl font-bold">Leaderboard</h1>
        <p className="mt-1 text-[13px] text-lightGray">{typeof totalUsers === 'number'
              ? totalUsers?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' DNB'
              : '0.00 DNB'} Holders</p>
      </div>

      <div className="mt-20 mb-[100px]">
        <LeaderBoardCard
          users={users}
        />
      </div>

  
    </div>
  );
};

export default Leaderboard;
